import React from 'react'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './index.module.css'

const Home = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Dev Summit - Latin America</title>
        <meta property="og:title" content="Dev Summit - Latin America" />
      </Helmet>
      <img
        alt="image"
        src="/playground_assets/dev%20summit%20logo-02%20%5B1%5D-200h.png"
        className={styles['image']}
      />
      <h1 className={styles['text']}>December 15, 2022</h1>
      <span className={styles['text1']}>
        If you want to receive more information about the event, register in the
        following form
      </span>
      <form className={styles['form']}>
        <input
          type="email"
          placeholder="Email"
          className={` ${styles['textinput']} ${projectStyles['input']} `}
        />
        <a
          href="mailto:sayhi@dragoshouse.com?subject=Coming Soon Form"
          className={` ${styles['link']} ${projectStyles['button']} `}
        >
          Submit
        </a>
      </form>
    </div>
  )
}

export default Home
